export default ({
  nodeStageJson,
  allMenuLinkContentMenuLinkContentJson: { nodes: menus = [] },
  allNodeStageJson
}) => {
  const {
    drupal_id,
    title,
    field_description,
    field_challenges,
    field_benefits,
    path,
    relationships
  } = nodeStageJson;
  const {
    field_stage_image,
    field_stage_secondary_image,
    field_species,
    field_stage_moments
  } = relationships;

  /**
   * Map specific stage to the previous/next stage nav link
   * @param {object} stage stage from CMS
   * @returns {object} stage navigation link
   */
  const stageNavBlock = (stage) => {
    return {
      secondaryImage: {
        path:
          stage.relationships.field_stage_secondary_image &&
          stage.relationships.field_stage_secondary_image.relationships
            .field_media_image.uri.url.publicURL,
        alt: stage.title
      },
      title: stage.title,
      description: stage.field_description && stage.field_description.value,
      roleTitle: stage.roleTitle,
      numberOfTopics: stage.relationships.field_stage_moments
        ? stage.relationships.field_stage_moments.length
        : 0,
      url: stage.path.alias,
      species: stage.species
    };
  };

  const currentIdx = menus.findIndex((menu) => menu.link.url === path.alias);

  /**
   * Uses the menu content type to select the previous stage
   * Uses the previous stage ID to get the whole stage object from allNodeStage
   * Utilises the stageNavBlock helper
   * @returns {object} stage navigation link
   */
  const getPreviousStage = () => {
    if (currentIdx === 0) return null;
    console.log('MENUS ', menus);
    const stageUrl = menus[currentIdx - 1].link.url;
    const stage = allNodeStageJson.nodes.find(
      (stage) => stage.path.alias === stageUrl
    );
    if (!stage) return null;
    stage.alignment = `card-top-right`;
    stage.roleTitle = `Previous Stage`;

    return stageNavBlock(stage);
  };

  /**
   * Uses the menu content type to select the next stage
   * Uses the next stage ID to get the whole stage object from allNodeStage
   * Utilises the stageNavBlock helper
   * @returns {object} stage navigation link
   */
  const getNextStage = () => {
    if (currentIdx === menus.length - 1) return null;
    const stageUrl = menus[currentIdx + 1].link.url;
    const stage = allNodeStageJson.nodes.find(
      (stage) => stage.path.alias === stageUrl
    );
    if (!stage) return null;
    stage.alignment = `card-bottom-left`;
    stage.roleTitle = `Next Stage`;

    return stageNavBlock(stage);
  };

  /**
   * Maps related moments on this Stage
   * @returns {array} a mapped list of related moments
   */
  const relatedMoments = () => {
    if (!field_stage_moments) return [];
    return field_stage_moments.map((moment) => {
      return {
        id: moment.drupal_id,
        image: {
          path:
            moment.relationships.field_moment_image &&
            moment.relationships.field_moment_image.relationships.image.uri.url,
          alt: moment.title
        },
        stageTitle: moment.relationships.field_moment_stage.title,
        title: moment.title,
        url: moment.path.alias,
        lifestage: moment.relationships.field_pet_lifestage[0].name,
        petParentRole:
          moment.relationships.field_pet_lifestage[0].field_parent_role,
        petParentRoleDescription:
          moment.relationships.field_pet_lifestage[0]
            .field_parent_role_description,
        petParentRoleImage: {
          path: moment.relationships.field_pet_lifestage[0].relationships
            .field_parent_role_image?.image.imageDerivatives.links.large.href,
          alt: moment.relationships.field_pet_lifestage[0].relationships
            .field_parent_role_image?.image.alt
        }
      };
    });
  };

  return {
    id: drupal_id,
    species: field_species.name.toLowerCase(),
    path: path.alias,
    hero: {
      title,
      description: field_description && field_description.value,
      image: {
        path:
          field_stage_image && field_stage_image.relationships.image.uri.url,
        alt: title
      },
      species: field_species.name.toLowerCase()
    },
    relatedMoments: {
      title: `Explore ${title} Moments`,
      moments: relatedMoments()
    },
    previousStage: getPreviousStage(),
    nextStage: getNextStage()
  };
};

import React from 'react';
import { graphql } from 'gatsby';

import munger from './stage.munger';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import StageTemplate from '@components/templates/StageTemplate/StageTemplate';
import GTMPageLoad from '@components/atoms/GTM/GTMPageLoad';

const StagePage = ({ data, location }) => {
  const stageJson = munger(data);

  return (
    <Layout location={location} species={stageJson.species}>
      <SEO
        title={stageJson.hero.title}
        description={stageJson.hero.description}
      />
      <GTMPageLoad
        {...{
          stageTitle: stageJson.hero.title,
          page_type: `stage`,
          page_taxonomy_field: `Stage`,
          page_species: stageJson.species,
          page_name: stageJson.hero.title
        }}
      />
      <StageTemplate data={stageJson} location={location} />
    </Layout>
  );
};

export const query = graphql`
  query StagePage($PageId: String!, $Species: String!) {
    nodeStageJson(drupal_id: { eq: $PageId }) {
      drupal_id
      title
      field_description {
        value
      }
      path {
        alias
      }
      field_description {
        value
      }
      relationships {
        field_species {
          name
        }
        field_stage_image {
          relationships {
            image {
              uri {
                url {
                  id
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
        field_stage_secondary_image {
          relationships {
            field_media_image {
              uri {
                url {
                  id
                  publicURL
                }
              }
            }
          }
        }
        field_stage_moments {
          drupal_id
          title
          path {
            alias
          }
          relationships {
            field_moment_image {
              relationships {
                image {
                  uri {
                    url {
                      id
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
            field_moment_stage {
              title
            }
            field_pet_lifestage {
              name
              field_parent_role
              field_parent_role_description
              relationships {
                field_parent_role_image {
                  image {
                    imageDerivatives {
                      links {
                        large {
                          href
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allMenuLinkContentMenuLinkContentJson(
      sort: { fields: weight, order: ASC }
      filter: { menu_name: { eq: $Species } }
    ) {
      nodes {
        drupal_id
        menu_name
        title
        link {
          url
        }
      }
    }
    allNodeStageJson {
      nodes {
        drupal_id
        title
        field_description {
          value
        }
        path {
          alias
        }
        relationships {
          field_stage_image {
            relationships {
              image {
                uri {
                  url {
                    id
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
          field_stage_moments {
            drupal_id
            title
            field_description {
              processed
            }
            path {
              alias
            }
            relationships {
              field_moment_image {
                image {
                  imageDerivatives {
                    links {
                      large {
                        href
                      }
                    }
                  }
                }
              }
              field_moment_stage {
                title
              }
              field_pet_lifestage {
                name
              }
            }
          }
          field_stage_secondary_image {
            relationships {
              field_media_image {
                uri {
                  url {
                    id
                    publicURL
                  }
                }
              }
            }
          }
          node__moment {
            drupal_id
          }
        }
      }
    }
  }
`;

export default StagePage;

import React from 'react';
import PropTypes from 'prop-types';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './InfoList.scss';

const InfoList = ({ list }) => {
  const items = list.map((item, index) => {
    return (
      <li key={item.text + index}>
        <MarsIcon icon={item.icon} width="40px" height="40px" />
        <p>{item.text}</p>
      </li>
    );
  });

  return <ul className="mars-info-list">{items}</ul>;
};

export default InfoList;

InfoList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired
};

import React from 'react';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './StageNavigationBlock.scss';

const StageNavigationBlock = ({ species, children }) => {
  const cleanChildren = Array.from(children).filter((item) => item !== null);
  return (
    <nav className="mars-stage-navigation mars-padding-y--xl">
      <div className="mars-container">
        <h2 className="mars-gamma mars-text--center">Related Stages</h2>
        <p className="mars-zeta mars-text-colour--text-meta mars-text--center mars-padding-bottom--lg">
          Within the {species} Parent Journey
        </p>
        <ul
          className={`mars-stage-navigation__list mars-grid mars-grid--2-cols ${
            cleanChildren.length === 1 ? `single-item` : ``
          }`}>
          {cleanChildren.map((child, i) => {
            return (
              <li
                key={i}
                className="mars-stage-navigation__list-item mars-margin-bottom--xs">
                {child}
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default StageNavigationBlock;

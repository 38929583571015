import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';
import BackStageHero from '../../molecules/BackStageHero/BackStageHero';

import './StagesHero.scss';
import { getDrupalURL } from '@helpers';

const StagesHero = ({ title, description, image, location }) => {
  return (
    <header className="mars-stages-hero">
      <div className="mars-container mars-grid mars-grid--2-cols">
        <div className="mars-stages-hero__content mars-padding-y--xxl">
          <BackStageHero location={location} />
          <span className="mars-stage__meta-title mars-zeta mars-text-colour--text-meta-hero">
            Stage
          </span>
          <h1 className="mars-stage__main-title mars-beta mars-margin-bottom--xs mars-text-colour--brand1">
            {title}
          </h1>
          {/* The value is used with dangerouslySetInnerHTML to preserve the HTML markup. */}
          <div
            className="mars-stages-hero__description mars-body-large"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <div>
          <div className="mars-stages-hero__image-container">
            {image && (
              // <img
              //   className="mars-stages-hero__image"
              //   src={`${getDrupalURL()}${image.path}`}
              //   alt={image.alt}
              // />
              <GatsbyImage
                className="mars-stages-hero__image"
                image={getImage(image.path)}
                alt={image.alt}
              />
            )}
          </div>
        </div>
      </div>
      <span className="mars-stages-hero__image-wave" />
    </header>
  );
};

export default StagesHero;

StagesHero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    path: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }),
  location: PropTypes.object.isRequired
};

import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Card from '@components/atoms/Card/Card';

import './StageContentBlock.scss';

import { getDrupalURL } from '@helpers';

const StageContentBlock = ({
  secondaryImage,
  title,
  description,
  roleTitle,
  url,
  species
}) => {
  const handleClick = (e) => {
    if (typeof onClick === `function`) {
      onClick();
    }
  };

  // Replace any non-alphanumeric character with a '-' to be used as a class name.
  const classNameTitle = title.toLowerCase().replace(/[\W_]+/g, `-`);

  return (
    <article className="mars-stage-content-block">
      <Card className="mars-padding--sm mars-stage-card">
        <div className="mars-stage-card__grid-container">
          <div className="mars-stage-card__image-container mars-padding-y--xs">
            {secondaryImage && (
              <>
                <img
                  className="mars-stage-card__image"
                  src={secondaryImage.path}
                  alt={secondaryImage.alt}
                />
                {/* <GatsbyImage
                  className="mars-stage-card__image"
                  image={getImage(secondaryImage.path)}
                  alt={secondaryImage.alt}
                /> */}
              </>
            )}
          </div>
          <div className="mars-stage-card__content">
            {roleTitle && (
              <span className="mars-zeta mars-text-colour--text-meta">
                {roleTitle}
              </span>
            )}
            <h3 className="mars-delta">{title}</h3>
            {/* The value is used with dangerouslySetInnerHTML to preserve the HTML markup. */}
            <div
              className="mars-body mars-body mars-stage-card__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <Link to={url} className="mars-button">
              Explore stage
            </Link>
          </div>
          <span
            className={`mars-stage-card__bg mars-stage-card__bg-${classNameTitle}`}
          />
          <span
            className={`mars-stage-card__bg-road mars-stage-card__bg-${classNameTitle}-road`}
          />
          <span
            className={`mars-stage-card__bg-paw mars-stage-card__bg-paw-${species}`}
          />
        </div>
      </Card>
    </article>
  );
};

export default StageContentBlock;

StageContentBlock.propTypes = {
  secondaryImage: PropTypes.shape({
    path: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  roleTitle: PropTypes.string,
  url: PropTypes.string.isRequired,
  species: PropTypes.string.isRequired
};

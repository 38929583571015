import React from 'react';
import InfoList from '@components/organisms/InfoList/InfoList';
import RelatedMoments from '@components/organisms/RelatedMoments/RelatedMoments';
import StageContentBlock from '@components/organisms/StageContentBlock/StageContentBlock';
import StagesHero from '@components/organisms/StagesHero/StagesHero';
import StageNavigationBlock from '@components/molecules/StageNavigationBlock/StageNavigationBlock';
import BackToOverview from '@components/molecules/BackToOverview/BackToOverview';
import TabbedContent from '@components/organisms/TabbedContent/TabbedContent';

// TODO: Double deconstruct data so we dont have to repeat data everywhere.
const StageTemplate = ({ data, location }) => {
  return (
    <>
      <StagesHero {...data.hero} location={location} />

      <RelatedMoments layout="staggered" {...data} tabbed={true} />

      <StageNavigationBlock species={data.species}>
        {data.previousStage && (
          <StageContentBlock {...data.previousStage} species={data.species} />
        )}
        {data.nextStage && (
          <StageContentBlock {...data.nextStage} species={data.species} />
        )}
      </StageNavigationBlock>

      <BackToOverview species={data.species} />
    </>
  );
};

export default StageTemplate;

import React from 'react';
import { Link } from 'gatsby';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './BackToOverview.scss';

const BackToOverview = ({species}) => {
  return (
    <div className="mars-back-to-overview mars-margin-bottom--xl">
      <div className="mars-container">
        <Link className={`mars-button mars-button--secondary`} to={`/${species}`}>
          <MarsIcon className="mars-margin-right--xxs mars-icon--back-to-overview" icon={`left`} />
          <span className={`mars-button__text`}>Back to journey overview</span>
        </Link>
      </div>
    </div>
  );
};

export default BackToOverview;
